
/** Components */
import { defineComponent } from "vue";
import BoatBookingCard from "./BoatBookingCard.vue";

/** Packages */
import dayjs, { Dayjs } from "dayjs";
import http from "@/utils/http-common"

/** Models */
import ICalendarWeek from "../../../interfaces/ICalendarWeek";
import ICalendarDay from "../../../interfaces/ICalendarDay";
import ITimeSlot from "../../../interfaces/ITimeSlot";
import ICustomTimeSlot from '@/interfaces/ICustomTimeSlot';
import { toast } from 'vue3-toastify';

export default defineComponent({
    name: "CustomDatePicker",
    props: {
        isActive: Boolean,
        id: Number,
        category: String,
        hideButtons: Boolean,
        admin: Boolean,
    },
    components: {
        BoatBookingCard,
    },
    data() {
        return {
            calendar: [] as ICalendarWeek[],
            customTimeSlots: [] as ICustomTimeSlot[],
            selectedDay: null as unknown as number,
            selectedDayObj: null as unknown as ICalendarDay,
            pickDate: false as boolean,
            selectedDate: null as unknown as string,
            currentDate: null as unknown as string,
            currentMonth: null as unknown as string,
            currentYear: null as unknown as string,
            showBooking: false as boolean,
            dayIndex: 0 as number,
            selectedTimeSlot: null as unknown as ITimeSlot,
            asAdmin: false as boolean,
        };
    },
    methods: {
        toggle(): void {
            this.pickDate = !this.pickDate;
        },
        handleClickDay(
            day: ICalendarDay,
            weekIndex: number,
            dayIndex: number
        ): void {
            if (!day.selectable || !day.isBookAble) {
                return;
            }

            if (this.dayIndex != dayIndex) {
                this.showBooking = true;
            } else {
                this.showBooking = !this.showBooking;
            }

            this.dayIndex = dayIndex;

            const bookingCard = this.$refs.bookingCard as HTMLTableRowElement;

            if (bookingCard && bookingCard.parentNode) {
                bookingCard.parentNode.removeChild(bookingCard);
            }

            const currentRow: Element =
                      this.$el.querySelectorAll("tbody tr")[weekIndex];
            currentRow.parentNode?.insertBefore(bookingCard, currentRow.nextSibling);

            this.setSelectedDay(day, weekIndex, dayIndex);

            this.selectedDate = dayjs(this.currentDate)
                .format("YYYY-MM-DD")
                .replace(/[0-9]{2}$/, this.selectedDay.toString());
        },
        async GenerateCalendar(): Promise<void> {
            this.customTimeSlots = await this.checkCustomTimeSlots();

            let firstDayIndex = dayjs(this.currentDate).date(1).day() - 1;
            if (firstDayIndex === -1) {
                firstDayIndex = 6;
            }

            let dayIdentifier = 0;

            let dayIndex: Dayjs = dayjs(this.currentDate)
                .subtract(1, "month")
                .date(
                    dayjs(this.currentDate).subtract(1, "month").daysInMonth() -
                    firstDayIndex +
                    1
                );

            const calendar: ICalendarWeek[] = [];

            let weekIndex           = 0;
            let week: ICalendarWeek = {id: weekIndex, days: []};

            for (let i = 0; i < 43; i++) {
                let isSelectable = true;
                let isCustomTimeSlot = false;
                let isBookAble = this.isBookAble(dayIndex, this.customTimeSlots);
                if(dayIndex.isBefore(dayjs())) {
                    isSelectable = false;
                }
                if (
                    i < firstDayIndex ||
                    i > dayjs(this.currentDate).daysInMonth() + firstDayIndex - 1
                ) {
                    isSelectable = false;
                }

                const today = dayjs();
                if (dayIndex.isSame(today, 'day')) {
                    isSelectable = true;
                }

                if (week.days.length === 7) {
                    calendar.push(week);
                    weekIndex++;
                    week = {id: weekIndex, days: []};
                }
                if(isSelectable) {
                    isCustomTimeSlot = this.dayFoundInCustomTimeSlot(dayIndex, this.customTimeSlots)
                }

                week.days.push({
                    id: dayIdentifier,
                    day: dayIndex.date(),
                    dayOfWeek: dayIndex.format("dddd"),
                    date: dayIndex.format("YYYY-MM-DD"),
                    selectable: isSelectable,
                    selected: false,
                    customTimeSlot: isCustomTimeSlot,
                    isBookAble: isBookAble
                });
                dayIndex = dayIndex.add(1, "day");
                dayIdentifier += 1;
            }

            this.calendar = calendar;
        },
        async checkCustomTimeSlots(): Promise<ICustomTimeSlot[]> {
            const startDate = dayjs(this.currentDate).startOf('month').format('YYYY-MM-DD');
            const endDate = dayjs(this.currentDate).endOf('month').format('YYYY-MM-DD');

            try {
                const res = await http.get(`custom_time_slot/dates`, {
                    params: {
                        start_date: startDate,
                        end_date: endDate
                    }
                });
                return res.data.customTimeSlots;
            } catch (error) {
                toast(this.$t('ERROR_SOMETHING_WENT_WRONG'), {
                    type: 'error',
                    position: 'top-right',
                    dangerouslyHTMLString: true,
                    autoClose: 3000
                })

                return [];
            }
        },
        dayFoundInCustomTimeSlot(dayIndex: dayjs.Dayjs, customTimeSlots: ICustomTimeSlot[]): boolean {
            for(const customTimeSlot of customTimeSlots) {
                const start = dayjs(customTimeSlot.start_date);
                const end = dayjs(customTimeSlot.end_date);
                if(dayIndex.date() >= start.date() && dayIndex.date() <= end.date()) {
                    return true
                }
            }
            return false;
        },
        isBookAble(dayIndex: dayjs.Dayjs, customTimeSlots: ICustomTimeSlot[]): boolean {
            let bookable = 0;
            for(const customTimeSlot of customTimeSlots) {
                const start = dayjs(customTimeSlot.start_date);
                const end = dayjs(customTimeSlot.end_date);
                if(dayIndex.date() >= start.date() && dayIndex.date() <= end.date()) {
                    bookable = Number(customTimeSlot.disable_appointments);
                }
            }
            return bookable !== 1;
        },
        nextMonth(): void {
            if (this.showBooking) {
                this.showBooking = false;
            }
            this.currentDate = dayjs(this.currentDate).add(1, "month").toString();
            this.setDateVariables();
            this.GenerateCalendar();
            this.selectedDay = 0;
        },
        previousMonth() {
            if (this.showBooking) {
                this.showBooking = false;
            }
            this.currentDate = dayjs(this.currentDate)
                .subtract(1, "month")
                .toString();
            this.setDateVariables();
            this.GenerateCalendar();
            this.selectedDay = 0;
        },

        setDateVariables() {
            this.currentMonth = dayjs(this.currentDate).format("MMMM");
            this.currentYear  = dayjs(this.currentDate).format("YYYY");
        },
        setSelectedDay(day: ICalendarDay, weekIndex: number, dayNumber: number) {
            if (day.selectable && this.selectedDay != day.day) {
                this.selectedDay = day.day;
                this.selectedDayObj = day;
            } else {
                let day = this.calendar[weekIndex].days.find(
                    (day) => day.day === dayNumber
                );
                if (day) {
                    day.selected = false;
                }
                this.selectedDay = 0;
            }
        },
        emitDate() {
            this.selectedDate = dayjs(this.currentDate)
                .format("YYYY-MM-DD")
                .replace(/[0-9]{2}$/, this.selectedDay.toString());
            this.$emit("setDate", dayjs(this.selectedDate).format("YYYY-MM-DD"));
            this.toggle();
        },
        handleReservation(slot: ITimeSlot) {
            this.selectedTimeSlot = slot;
            this.$emit('startReservation', slot, this.selectedDate);
        },
        nextStep(): void {
            if(!this.selectedDate || !this.selectedTimeSlot) {
                toast(this.$t('ERROR_FRONTEND_DATE_PICKER_NO_DATE_SELECTED'), {
                    type: 'error',
                    position: 'top-right',
                    dangerouslyHTMLString: true,
                    autoClose: 3000
                })
                return;
            }
            this.$emit('nextStep');
        },
        prevStep(): void {
            this.$emit('prevStep')
        }
    },
    mounted() {
        if(this.admin) {
            console.log('true');
            this.asAdmin = true;
        }
        this.showBooking = false;
        this.currentDate = dayjs().toString();

        this.setDateVariables();
        this.GenerateCalendar();
    },
});
